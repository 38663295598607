<template>
  <v-dialog v-model="dialog" width="auto" max-width="455" style="z-index: 999">
    <v-card class="mx-auto" v-if="!flagging">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #FFDBDE"
      >
        <v-btn class="icon-check" style="background-color: #FF495C" icon fab>
          <v-icon color="white" large>mdi-alert-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8 d-flex justify-center align-center"
        ><span>
          {{ $t("flagging.selectProperties") }}
          <span
            style="color: #1D4180"
            class="pl-2 d-flex justify-center align-center"
          ></span>
        </span>
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        {{ $t("flagging.cannotBeUndone") }}
        <v-row class="pl-16 pr-16 pt-5">
          <v-switch
            v-model="deletePolygons"
            :label="$t('flagging.deletePolygons')"
          ></v-switch>
        </v-row>
        <v-row class="pl-16 pr-16  align-center">
          <v-switch
            v-model="allTimesteps"
            :hide-details="allTimesteps"
            :label="$t('flagging.applyForAllTimesteps')"
          ></v-switch>
        </v-row>
        <v-row v-if="allTimesteps" class="red--text ">
          <v-col>
            <span>{{ $t("flagging.fromWholeTimeseries") }}</span>
          </v-col>
        </v-row>
        <v-row class="pl-16">
          <v-switch
            class="pt-0 "
            hide-details
            v-model="allLayer"
            :label="$t('flagging.applyForAllLayers')"
          ></v-switch>
        </v-row>

        <div v-if="!allLayer" class="pl-16">
          <v-row
            class="pl-10"
            v-for="layer in layerSelection"
            :key="layer.product"
          >
            <v-checkbox
              :value="layer"
              :label="layer.title"
              v-model="selectedLayer"
              hide-details
            ></v-checkbox>
          </v-row>
        </div>
        <!--        <v-row class="pt-8 black&#45;&#45;text">-->
        <!--          <v-col-->
        <!--            md="6"-->
        <!--            v-for="product in layerSelection"-->
        <!--            :key="product.title"-->
        <!--            class="d-flex py-0 justify-center"-->
        <!--          >-->
        <!--            <span>{{ product.title }}</span>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <!--        <v-row> timesteps {{ timestepSelection.length }}</v-row>-->
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" text @click="$emit('closeDialog')">
          {{ $t("cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="primary" rounded @click="startFlagging">
          {{ $t("flagging.startFlagging") }}
        </v-btn>
        <flagging-success-dialog
          :dialog="successDialog"
          @closeDialog="successDialog = false"
        ></flagging-success-dialog>
      </v-card-actions>
    </v-card>
    <v-overlay v-if="flagging" opacity="0.8" absolute>
      <div>
        <v-progress-circular indeterminate color="white" size="64"
          ><span>{{ count }}/{{ maxCount }}</span></v-progress-circular
        >
      </div>
    </v-overlay>
  </v-dialog>
</template>
<script>
import FlaggingSuccessDialog from "@/core/components/flagging/FlaggingSuccessDialog.vue";
import axios from "axios";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import { mapState } from "vuex";

export default {
  name: "FlaggingOverview",
  components: { FlaggingSuccessDialog },
  mixins: [rasterLayerMixin],
  props: {
    region: Object,
    dialog: { type: Boolean, default: false },
    layerSelection: { type: Array, default: () => [] },
    timeStep: { type: Object, default: () => {} },
    featureCollection: { type: Array, default: () => [] }
  },
  data: () => ({
    successDialog: false,
    selectedLayers: [],
    selectedLayer: [],
    timestepSelection: [],
    loading: false,
    flagging: false,
    allLayer: true,
    allTimesteps: false,
    deletePolygons: true,
    count: 0,
    maxCount: null
  }),
  computed: {
    ...mapState("management", ["accessToken"])
  },
  methods: {
    async createLayerAndTimestepSelection() {
      //  this.loading = true;
      if (this.allLayer) {
        this.selectedLayers = this.layerSelection;
      } else {
        this.selectedLayers = this.selectedLayer;
      }
      if (this.allTimesteps) {
        let timestepsSet = new Set();
        // const layers = [];
        let requests = [];
        this.selectedLayers.forEach(layer => {
          requests.push(
            this.fetchAllTimesteps(layer.layerId, false, this.accessToken).then(
              results => {
                if (results) {
                  results.forEach(timestep => {
                    timestepsSet.add(timestep.datetime);
                  });
                }
              }
            )
          );
        });
        await Promise.all(requests);
        this.timestepSelection = Array.from(timestepsSet);
        this.maxCount = this.timestepSelection.length;
        // this.loading = false;
      }
      //else {
      // this.loading = false;
      //}
    },
    async createData() {
      if (!this.allTimesteps) {
        await this.createPostData(this.timeStep.datetime);
      } else {
        let requests = [];
        this.timestepSelection.forEach(async timestep => {
          requests.push(
            this.createPostData(timestep).then(() => {
              this.count += 1;
            })
          );
        });
        await Promise.all(requests);
      }
    },
    async createPostData(timestep) {
      const layers = [];
      let requests = [];

      this.selectedLayers.forEach(layer => {
        requests.push(
          this.checkTimeStepForLayer(layer, timestep).then(results => {
            if (results) {
              layers.push({
                layer_id: layer.layerId,
                product: layer.product
              });
            }
          })
        );
      });

      await Promise.all(requests);
      const data = {
        timestep: timestep,
        layer: layers,
        mask: {
          type: "FeatureCollection",
          features: this.featureCollection
        }
      };
      data.mask.features.forEach(feature => {
        feature.id = 0;
      });
      this.allData = data;
      await this.postMask(data);
    },
    async checkTimeStepForLayer(layer, timestep) {
      let response = await this.fetchAllTimesteps(layer.layerId, false);
      const timestepExists = response.filter(
        step => step.datetime === timestep
      );
      return !!timestepExists.length;
    },
    async postMask(data) {
      await axios.post(`/userdata/region/${this.region.id}/mask`, data);
      if (this.deletePolygons) {
        this.$emit("deletePolygons");
      }
      this.$emit("reload");
    },
    async startFlagging() {
      this.flagging = true;
      this.createLayerAndTimestepSelection().then(async () => {
        await this.createData();
        this.flagging = false;
        this.successDialog = true;
        this.$emit("closeDialog");
      });
    }
  },
  created() {
    //this.createLayerAndTimestepSelection();
  }
};
</script>

<style scoped>
#close-btn {
  z-index: 1;
}
.icon-check {
  position: absolute;
  top: 90px;
  left: 180px;
}

.icon-text {
  /* v-text/body-small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}

.div-hover:hover {
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>
