<template>
  <div>
    <div>
      <v-card class="card-dialog">
        <v-card-title>Flagging Tool</v-card-title>
        <!--        <v-card-subtitle class="red&#45;&#45;text pb-1">{{-->
        <!--          $t("flagging.flaggingCannotUndone")-->
        <!--        }}</v-card-subtitle>-->
        <v-card-text class="px-7">
          <v-row>
            <v-col>
              <v-select
                style="max-width: 320px"
                :items="flaggingModes"
                v-model="selectedFlaggingMode"
                item-text="label"
                return-object
                hide-details
                :label="$t('flagging.flagMode')"
              ></v-select>
            </v-col>
          </v-row>
          <div class="ml-0  pl-3 pt-8">
            <v-row>
              {{ $t("flagging.chooseDrawMode") }}
            </v-row>
            <v-row>
              <v-col class="pl-0 pt-5" cols="auto">
                <v-tooltip top>
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      small
                      fab
                      elevation="0"
                      v-on="onTooltip"
                      @click="drawPolygon"
                      :style="backgroundColor"
                    >
                      <v-icon>mdi-vector-polygon</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("flagging.polygons") }}
                </v-tooltip>
              </v-col>
              <v-col class="pl-0 pt-5" cols="auto">
                <v-tooltip top>
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      v-on="{ ...onTooltip }"
                      @click="drawFreehand"
                      :style="backgroundColor"
                    >
                      <v-icon>mdi-lead-pencil</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("flagging.freehand") }}
                </v-tooltip>
              </v-col>
              <v-divider class="my-3 mt-5" vertical></v-divider>

              <v-col class="pl-2 pt-5 pr-0">
                <v-tooltip top>
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      @click="drawUpdatedEvent"
                      fab
                      small
                      :disabled="featureCollection.length === 0"
                      v-on="{ ...onTooltip }"
                      :style="backgroundColor"
                      elevation="0"
                    >
                      <v-icon>mdi-vector-polyline-edit</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("flagging.edit") }}
                </v-tooltip>
              </v-col>
              <v-col class="pt-5 pl-0" v-if="mode !== ''">
                <v-tooltip top>
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      @click="cancelDrawMode"
                      icon
                      v-on="{ ...onTooltip }"
                      elevation="0"
                    >
                      <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("cancel") }}
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <div v-if="featureCollection.length" class="ml-0  pl-3 pt-10">
            <v-row>
              <p>{{ $t("flagging.modesExistingPolygons") }}</p>
            </v-row>
            <div class="pt-3">
              <div v-for="mode in flaggingModes" :key="mode.label">
                <v-row class="pl-3 " v-if="getCountFeatureInMode(mode) > 0">
                  <v-col>
                    <span>
                      {{ mode.label }}:
                      {{ getCountFeatureInMode(mode) }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <v-divider class="mt-2"></v-divider>
          <!--          <div>-->
          <!--            <v-switch-->
          <!--              v-model="deletePolygons"-->
          <!--              :label="$t('flagging.deletePolygons')"-->
          <!--            ></v-switch>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-switch-->
          <!--              class="pt-0 mt-0"-->
          <!--              hide-details-->
          <!--              v-model="allLayer"-->
          <!--              :label="$t('flagging.applyForAllLayers')"-->
          <!--            ></v-switch>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <v-switch-->
          <!--              v-model="allTimesteps"-->
          <!--              :label="$t('flagging.applyForAllTimesteps')"-->
          <!--            ></v-switch>-->
          <!--          </div>-->
          <!--          <div v-if="!allLayer && loaded">-->
          <!--            <v-row-->
          <!--              class="pl-10"-->
          <!--              v-for="layer in layerSelection"-->
          <!--              :key="layer.product"-->
          <!--            >-->
          <!--              <v-checkbox-->
          <!--                :value="layer"-->
          <!--                :label="layer.title"-->
          <!--                v-model="selectedLayer"-->
          <!--                hide-details-->
          <!--              ></v-checkbox>-->
          <!--            </v-row>-->
          <!--          </div>-->
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-row class="pl-2">
            <v-col>
              <v-btn
                color="primary"
                @click="overviewDialog = true"
                elevation="0"
                width="98%"
                :disabled="!featureCollection.length"
                ><span
                  >{{ $t("flagging.continue") }}({{
                    this.featureCollection.length
                  }})</span
                >
              </v-btn>
              <flagging-overview
                :dialog="overviewDialog"
                :region="region"
                :layer-selection="layerSelection"
                :time-step="timestep"
                :feature-collection="featureCollection"
                @closeDialog="overviewDialog = false"
                @deletePolygons="deleteAllPolygons"
                @reload="reloadSettings"
              ></flagging-overview>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import popUpMixin from "@/core/mixins/popUp.mixin";
import { mapActions, mapState } from "vuex";
import FlaggingOverview from "@/core/components/flagging/FlaggingOverview.vue";

export default {
  name: "FlaggingTool",
  components: { FlaggingOverview },
  mixins: [popUpMixin],
  props: {
    map: Object,
    region: Object,
    timestep: Object,
    layerSelectionAll: Array
  },
  data: () => ({
    featureCollection: [],
    mode: "",
    flaggingModes: [
      { label: "Cloud shadow", value: 1, color: "#FF0000" },
      { label: "Shallow water", value: 2, color: "#00FF00" },
      { label: "Sunglint", value: 3, color: "#0000FF" },
      { label: "Shadow", value: 4, color: "#FFFF00" },
      { label: "Invalid pixel", value: 5, color: "#00FFFF" },
      {
        label: "Invalid pixel overwriting cloud pixel",
        value: 6,
        color: "#FF00FF"
      },
      { label: "Floating vegetation/stuff", value: 7, color: "#000000" },
      { label: "Land", value: 8, color: "#FFFFFF" },
      { label: "Cloud", value: 9, color: "#808080" }
    ],
    selectedFlaggingMode: null,
    allLayer: true,
    allTimesteps: false,
    selectedLayer: [],
    loaded: false,
    deletePolygons: true,
    overviewDialog: false
  }),
  computed: {
    ...mapState("management", ["accessToken"]),
    layerSelection() {
      return this.layerSelectionAll.filter(layer => layer.product !== "rgb");
    },
    backgroundColor(mode) {
      if (mode === "freemode" || mode === "drawmode") {
        return {
          "background-color": "#40ADD5"
        };
      } else {
        return { "background-color": "" };
      }
    }
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    getCountFeatureInMode(mode) {
      const filtered = this.featureCollection.filter(
        feature => feature.properties.flag === mode.value
      );
      if (filtered.length) {
        return filtered.length;
      } else {
        return 0;
      }
    },
    deleteAllPolygons() {
      this.$store.draw.deleteAll();
      this.featureCollection = [];
    },
    reloadSettings() {
      this.$store.draw.changeMode("static");
      this.map.getCanvas().style.cursor = "pointer";
    },
    cancelDrawMode() {
      this.mode = "";
      this.map.dragPan.enable();
      this.$store.draw.changeMode("static");
      this.map.getCanvas().style.cursor = "grab";
    },
    drawUpdatedEvent() {
      this.mode = "editMode";
      this.showSnackbar({
        show: true,
        message: this.$t("flagging.editFeature"),
        color: "success",
        timeout: 10000
      });
      this.$store.draw.changeMode("simple_select");
      this.map.on("draw.delete", e => {
        console.log(e);
        const data = this.$store.draw.getAll();
        let newData = [];
        data.features.forEach(feature => {
          const filtered = this.featureCollection.filter(
            item =>
              item.geometry.coordinates[0][0][0] ===
                feature.geometry.coordinates[0][0][0] &&
              item.geometry.coordinates[0][0][1] ===
                feature.geometry.coordinates[0][0][1]
          );
          if (filtered.length) {
            feature.properties.flag = filtered[0].properties.flag;
            newData.push(feature);
          }
        });
        this.featureCollection = newData;
      });
      this.map.on("draw.update", e => {
        const id = e.features[0].id;
        try {
          this.featureCollection.forEach(feature => {
            if (feature.id === id) {
              feature.geometry = e.features[0].geometry;
            }
          });
          this.showSnackbar({
            show: true,
            message: this.$t("flagging.featureEdited"),
            color: "success"
          });
        } catch (e) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      });
    },
    drawFreehand() {
      this.mode = "freeMode";
      this.map.dragPan.disable();
      this.showSnackbar({
        show: true,
        message: this.$t("flagging.freehandDrawing"),
        color: "success"
      });
      this.$store.draw.changeMode("draw_free");
      this.map.getCanvas().style.cursor = "crosshair";
      this.map.on("draw.createFree", entry => {
        entry.features[0].properties.flag = this.selectedFlaggingMode.value;
        const existingFeature = this.featureCollection.filter(
          feature => feature.id == entry.features[0].id
        );
        if (!existingFeature.length) {
          this.featureCollection.push(entry.features[0]);
        }
        this.$store.draw.changeMode("static");
        this.mode = "";
        this.map.dragPan.enable();
        this.map.getCanvas().style.cursor = "grab";
      });
    },

    drawPolygon() {
      this.mode = "drawMode";
      this.showSnackbar({
        show: true,
        message: this.$t("flagging.polygonDrawing"),
        color: "success"
      });
      this.map.dragPan.enable();
      this.$store.draw.changeMode("draw_polygon");
      this.map.getCanvas().style.cursor = "crosshair";
      this.map.on("draw.create", entry => {
        entry.features[0].properties.flag = this.selectedFlaggingMode.value;
        const existingFeature = this.featureCollection.filter(
          feature => feature.id == entry.features[0].id
        );
        if (!existingFeature.length) {
          this.featureCollection.push(entry.features[0]);
        }
        // is there a better way to change the mode back to draw_polygon after finishing the polygon?
        setTimeout(() => {
          this.layerEditMode = false;
          this.$store.draw.changeMode("draw_polygon");
        }, 100);
      });
    }
  },
  created() {
    this.selectedFlaggingMode = this.flaggingModes[0];
    this.loaded = true;
  }
};
</script>
<style scoped>
.card-dialog {
  width: 20%;
  left: 0.5%;
  min-height: 300px;
  position: absolute;
  max-width: 300px;
  min-width: 210px;
  top: 80px;
  z-index: 2;
}
</style>
