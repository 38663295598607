<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> {{ $t("deleteConfirmation") }}</v-card-title>
      <v-card-text
        >{{
          layer.title +
            ":" +
            " " +
            (selectedDatetime && selectedDatetime.datetime
              ? selectedDatetime.datetime.replace("T", "\u00A0\u00A0")
              : "")
        }}
        <v-checkbox
          :label="$t('deleteAllLayers')"
          color="red"
          @change="reloadCard"
        ></v-checkbox>
        <div v-if="deleteAllLayers">
          <ul v-for="layer in layersArray" :key="layer.id">
            <li>{{ layer }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-progress-circular
          v-if="isDeleting"
          :size="30"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn color="red" text @click="deleteScene" :disabled="isDeleting"
          >{{ $t("delete") }}
        </v-btn>
        <v-btn color="primary" text @click="closeDialog"
          >{{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "ConfirmSceneDeleteDialog",
  data: () => ({
    deleteAllLayers: false,
    confirmationDialog: false,
    isDeleting: false,
    layersArray: []
  }),
  props: {
    selectedDatetime: Object,
    layer: Object,
    dialog: Boolean,
    layerSelection: Array,
    timesteps: Array
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async deleteScene() {
      this.isDeleting = true;
      const regionId = this.layer.regionId;
      const dt = this.selectedDatetime.datetime;

      let layer_ids = [this.layer.layerId];
      if (this.deleteAllLayers) {
        layer_ids = [];
        this.layerSelection.forEach(layer => layer_ids.push(layer.layerId));
        layer_ids = layer_ids.join(',');
      }

      try {
        const url = `/userdata/raster-data/region/${regionId}/layer/${layer_ids}/datetime/${dt}`;
        await axios.delete(url);
      } catch {
        // do nothing
      }

      this.isDeleting = false;

      this.closeDialog();
      const index = this.timesteps.findIndex(step => step.datetime === dt);
      this.$emit("reloadDateTimes", index);
    },
    reloadCard() {
      this.deleteAllLayers = !this.deleteAllLayers;

      if (this.deleteAllLayers === true) {
        this.layersArray = [];

        for (const layer of this.layerSelection) {
          this.layersArray.push(layer.title);
        }
      }
    }
  }
};
</script>
<style scoped>
.trash-icon {
  z-index: 2;
  width: 30px;
  position: absolute;
  left: 85%;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
