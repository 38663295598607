import simplify from "@turf/simplify";

const FreehandMode = {};

FreehandMode.onSetup = function() {
  const polygon = this.newFeature({
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [[]]
    }
  });

  this.addFeature(polygon);

  return {
    polygon,
    currentVertexPosition: 0,
    dragMoving: false
  };
};

FreehandMode.onDrag = FreehandMode.onTouchMove = function(state, e) {
  state.dragMoving = true;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
};

FreehandMode.onMouseUp = function(state, e) {
  if (state.dragMoving) {
    this.simplify(state.polygon);
    this.fireUpdate(state, e);
  }
};

FreehandMode.onTouchEnd = function(state, e) {
  this.onMouseUp(state, e);
};

FreehandMode.fireUpdate = function(state) {
  const feature = {
    id: state.polygon.id,
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: state.polygon.coordinates,
      type: "Polygon"
    }
  };
  this.map.fire("draw.createFree", {
    action: "move",
    features: [feature]
  });
};

FreehandMode.simplify = function(polygon) {
  const tolerance = 1 / Math.pow(2.05, 10 * this.map.getZoom()); // https://www.desmos.com/calculator/nolp0g6pwr
  simplify(polygon, {
    mutate: true,
    tolerance: tolerance,
    highQuality: true
  });
};

FreehandMode.toDisplayFeatures = function(state, geojson, display) {
  display(geojson);
};

export default FreehandMode;
