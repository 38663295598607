<template>
  <div>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="eye-button"
          tile
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="eye-button"
          @click="showProduct = !showProduct"
        >
          <v-icon color="grey darken-3" v-if="showProduct">mdi-layers</v-icon>
          <v-icon v-else class="text-decoration-line-through"
            >mdi-layers-outline</v-icon
          >
        </v-btn>
      </template>
      <span v-if="!showProduct">{{ $t("flagging.showProductLayer") }}</span>
      <span v-if="showProduct">{{ $t("flagging.hideProductLayer") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="rgb-button"
          tile
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="rgb-button"
          @click="showRGB = !showRGB"
        >
          <v-icon color="grey darken-3" v-if="showRGB" small>RGB</v-icon>
          <v-icon v-else class="text-decoration-line-through" small>RGB</v-icon>
        </v-btn>
      </template>
      <span v-if="!showRGB">{{ $t("flagging.showRGBLayer") }}</span>
      <span v-if="showRGB">{{ $t("flagging.hideRGBLayer") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="vs-button"
          tile
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="vs-button"
          @click="showVS = !showVS"
        >
          <v-icon color="grey darken-3" v-if="showVS">mdi-map-marker</v-icon>
          <v-icon v-else class="text-decoration-line-through"
            >mdi-map-marker-outline</v-icon
          >
        </v-btn>
      </template>
      <span v-if="!showVS">{{ $t("flagging.showVSLayer") }}</span>
      <span v-if="showVS">{{ $t("flagging.hideVSLayer") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template #activator="{ on: onTooltip }">
        <v-btn
          id="timestep-zoom"
          tile
          absolute
          top
          right
          fab
          small
          v-on="{ ...onTooltip }"
          class="layer-zoom"
          @click="zoomToTimestep(layer.layerId, timestep.datetime)"
        >
          <v-icon color="grey darken-3">mdi-crosshairs</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("flagging.zoomToTimestep") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";

export default {
  name: "FlaggingRightSideMenu",
  props: {
    map: Object,
    layer: Object,
    timestep: Object
  },
  mixins: [coastLayerMixin, rasterLayerMixin],
  data: () => ({
    showProduct: true,
    showRGB: true,
    showVS: true
  }),
  watch: {
    showProduct() {
      this.$emit("toggleLayer", this.showProduct);
    },
    showRGB() {
      this.$emit("toggleRGBLayer", this.showRGB);
    },
    showVS() {
      this.$emit("toggleVSLayer", this.showVS);
    }
  }
};
</script>
<style scoped>
#timestep-zoom {
  margin-top: 70px;
  right: 10px;
}

#eye-button {
  margin-top: 120px;
  right: 10px;
}
#rgb-button {
  margin-top: 170px;
  right: 10px;
}
#vs-button {
  margin-top: 220px;
  right: 10px;
}
</style>
