<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="455"
    style="z-index: 99"
    persistent
  >
    <v-card id="canvas " ref="canvas" class="mx-auto">
      <v-card
        elevation="0"
        class="white--text align-end"
        height="120px"
        style="background-color: #d7f3e1"
      >
        <v-btn class="icon-check" style="background-color: #35C168" icon fab>
          <v-icon color="white" large>mdi-check</v-icon>
        </v-btn>
      </v-card>
      <v-card-title class="pt-8  d-flex justify-center align-center">
        {{ $t("flagging.success") }}
      </v-card-title>

      <v-card-text class="pt-3 text-center align-center">
        <v-row>
          {{ $t("flagging.flaggingInfoText") }}
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" text @click="$emit('closeDialog')">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FlaggingSuccessDialog",
  props: {
    dialog: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.icon-check {
  position: absolute;
  top: 90px;
  left: 195px;
}

.icon-text {
  /* v-text/body-small */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.icon-symbol {
  font-size: 38px;
}
</style>
